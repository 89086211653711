/* ################################## Global styles ################################## */

:root {
  --color-1: #643B07;
  --color-2: #f5dd74;
  --color-3: #D9D9D9;
  --color-4: #FBFBFB;
  --color-5: #ECDAC4;
  --color-6: #000000;
  /* primary blue (pd)*/
  --pb-l: #edf4fc;
  --pb-l-hover: #e3eefa;
  --pb-l-active: #c6dcf4;
  --pb-n: #478edc;
  --pb-n-hover: #4080c6;
  --pb-n-active: #3972b0;
  --foundation-blue-dark: #356ba5;
  --pb-d-hover: #2b5584;
  --pb-d-active: #204063;
  --pb-darker: #19324d;
  /* fonts */
  --font-1: 'Montserrat', sans-serif;
  /* --font-1: 'Dancing Script', cursive; */
  --font-2: 'Young Serif', serif;
  --font-3: 'Satisfy', cursive;
  --font-4: 'Urbanist';
}

.tempBackground {
  background-color: var(--color-3);
  color: #000000;
}

.title {
  font-size: 40px;
  font-family: var(--font-1)
}

.body {
  font-size: 25px;
  font-family: var(--font-2);
}

.botPad {
  margin-bottom: 20px;
}

.bordered {
  border: 1px solid black;
}

.App {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .body {}
}

/* ################################## Page styles ################################## */

/* details page styles */

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
}

.gridItem {
  padding: 30px;
}

.prodImg {
  padding: 10px;
  height: 30rem;
}

.prodImgs {
  margin-top: 50px;
  width: min-content;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
  max-height: calc(100vh + 20vh);
}

.miniProdImg {
  width: 8rem;
  margin: 10px;
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
}

.miniProdImgs {
  position: sticky;
  top: 0;
}

.detailsContainer {
  display: flex;
  width: fit-content;
}

.detailsContainer .header h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

.detailsContainer .header p {
  font-size: 2rem;
}

.prodDescription p {
  margin-top: 30px;
  font-size: 18px;
  text-align: start;
  padding: 10px;
}

#size button {
  width: 8rem;
  height: 3rem;
}

.specs {
  margin: 30px 0;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {

  .miniProdImg {
    display: none;
  }

  .grid {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* About styles */

#aboutPage {
  margin: 15px 0 0 0;
}

.aboutImg {
  margin: 25px 0;
  width: 548px;
  height: 548px;
  flex-shrink: 0;
  border-radius: 548px;
  background: url('./Assets/stu.jpeg'), lightgray 50% / cover no-repeat, #D9D9D9;
}

.aboutTitle {
  color: var(--foundation-blue-normal, #478EDC);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--font-1);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 92px;
  letter-spacing: -1.481px;
}

.aboutMsgContainer {
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto;
  color: var(--foundation-blue-dark, #356BA5);
  text-align: center;
  font-family: var(--font-1);
  font-size: 20px;
  line-height: 38px;
  letter-spacing: -1.481px;
}

.aboutMsg {}

.contactFormSection {
  padding: 25px;
  border-top: 1px solid black;
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.aboutContactDescription {
  margin: 25px 0 0 0;
}

.aboutContactIcon {
  width: 25px;
  padding: 5px;
}

.aboutContactTimes {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--font-1);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.481px;
}

.aboutContactSubTitle {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--font-1);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.481px;
}

.aboutContactNumber {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.481px;
}

.aboutContactEmail {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
}

.aboutContactInputs {
  margin: 0 15px;
}

.aboutInputRow {
  margin: 25px 0;
}

.aboutFormSubmit {
  padding: 10px 20px;
  border-radius: 48px;
  background: var(--foundation-blue-normal, #478EDC);
}


@media screen and (max-width: 600px) {

  .aboutImg {
    width: 370px;
    height: auto;
  }
}

/* home styling */

#homeTitle {
  width: 725px;
  height: 186px;
  flex-shrink: 0;
  text-align: center;
  margin: 25px auto;
  color: var(--foundation-blue-dark, #356BA5);
  text-align: center;
  font-family: 'Urbanist', sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 500;
  line-height: 95px;
  letter-spacing: -6px;
  text-transform: capitalize;
}

.homeMarquee {
  padding: 15px;
  font-size: 200px;
  border: 1px solid black;
  color: var(--foundation-blue-normal-active, #3972B0);
  font-family: 'Italiana', sans-serif;
  font-size: 200px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.homeTitleImg {
  width: 80%;
  height: 60rem;
  background-image: url('./Assets//shop.jpg');
  margin: 70px auto;
  background-size: cover;
  background-position: center;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr .5fr .5fr .5fr .5fr;
  height: fit-content;
}

.homeTitleTxt {
  color: var(--foundation-blue-normal-active, #3972B0);
  font-family: 'Urbanist', 'san-sarif';
  font-size: 67px;
  font-style: normal;
  font-weight: 700;
  line-height: 126%;
  text-transform: uppercase;
  text-align: left;
  margin: 60px;
}

.homeTitleTxtFooter {
  color: var(--foundation-blue-normal-active, #3972B0);
  font-family: Urbanist, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 180%;
  text-transform: uppercase;
}

.hc2 {
  background-position: center;
  background-size: cover;
  height: 615px;
  margin: 60px;
  background-image: url('./Assets/croissants.jpg');
}

.hc3 {
  border: 1px solid black;
  background-position: center;
  background-size: cover;
  height: 615px;
  margin: 60px;
  background-image: url('./Assets/fancyCup.jpg');
}

.hc3:hover .hc3t,
.hc5:hover .hc5t {
  color: var(--pb-n-hover);
  transition: all ease-in-out 300ms;
}

.hc3:hover {
  border: 3px solid var(--pb-n-hover);
  transition: all ease-in-out 300ms;
  background-image: url('./Assets/fancyCup.jpg');
}

.hc5 {
  background-position: center;
  background-size: cover;
  height: 615px;
  margin: 60px;
  background-image: url('./Assets/kneeingDough.jpg');
}

.hc5:hover {
  border: 3px solid var(--pb-n-hover);
  transition: all ease-in-out 0.3s;
  background-image: url('./Assets/fancyCup.jpg');
}

.hc6 {
  margin: 250px 50px;
  height: 525px;
}

.hc3t {
  color: #FFF;
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  position: relative;
  top: 35%;
  margin-left: 25px;
  width: 300px;
  line-height: 70px;
}

.hc5t {
  color: #FFF;
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  position: relative;
  top: 65%;
  /* margin-left: 25px; */
  width: 300px;
  line-height: 70px;
}

.homeBeanBanner {
  width: 250px;
  position: relative;
  bottom: 10%;
  left: 35%;
}

.homeLink {
  text-decoration: none;
}

.homeLinkArrow {
  height: 96px;
  width: 96px;
  color: #000;
  margin-bottom: 12px;
  position: relative;
}

.homeImgContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
  /* gap: 3px;
  overflow: hidden; */
}

.homeFollowImg {
  width: 260px;
  height: 262px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
}

.homeFollowTxt {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  margin-top: 35px;
  line-height: 24px;
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {

  #homeTitle {
    width: 50%;
    height: fit-content;
    text-align: left;
  }

  .gridContainer {
    display: flex;
    flex-direction: column;
  }

  .hc6 {
    height: 80%;
    width: 80%;
    margin: 30px 20px;
  }

  .homeTitleTxt {
    margin: 10px;
    text-align: center;
  }

  .hc3t {
    margin-left: 0;
  }

}

/* Merch styles */

#merchPage {
  height: fit-content;
}

#merchBackground a {
  font-size: x-large;
}

#totalMerchContainer {
  display: flex;
  justify-content: space-around;
  margin: 50px 0px;
  flex-wrap: wrap;
}

/* Menu Styles */

#menuPage {}

.menuSection {
  width: 80%;
  margin: 57px auto;
}

.menuTitle {
  margin-bottom: 25px;
  padding: 76px 0;
  color: var(--foundation-blue-dark, #356BA5);
  font-family: Montserrat;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  letter-spacing: -8px;
  text-transform: capitalize;
  border-bottom: 1px solid var(--foundation-blue-dark);
}

.menuGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mc1 {
  display: flex;
  align-items: center;
  padding: 50px 0;
  border-right: 1px solid var(--foundation-blue-dark);
  border-top: 1px solid var(--foundation-blue-dark);
}

.menuLogoContianer {
  display: flex;
  align-items: center;
}

.menuLogo {
  height: 196px;
  width: 196px;
}

.menuLogoTxt {
  display: flex;
  width: 121px;
  height: 126px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--foundation-blue-dark, #356BA5);
  text-align: center;
  font-family: Urbanist, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  /* 120.833% */
}

.mc2,
.mc3 {
  display: flex;
  align-items: center;
  height: 50%;
  border-top: 1px solid var(--foundation-blue-dark);
}

.menuFooter {
  color: var(--foundation-blue-dark-hover, #2B5584);
  font-family: var(--font-4);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 95px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--foundation-blue-dark);
}

.menuBorder {
  border: 1px solid var(--foundation-blue-dark-hover, #2B5584);
  background: #FBFBFB;
}

.menuOptionsContainer {
  list-style: none;
}

.menuOptionDisabled {
  text-decoration: line-through;
}

.menuSectionTitle {
  max-height: 100px;
  color: var(--foundation-blue-dark, #356BA5);
  font-family: var(--font-4);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  text-transform: uppercase;
  transform: rotate(-90deg);
  width: fit-content;
  height: fit-content;
}

.menuOption {
  width: 300px;
  padding: 30px;
  text-align: left;
}

.menuOptionLink {
  text-decoration: none;
}

.menuOption:hover {
  cursor: pointer;
}

.menuOptionTitle {
  color: var(--foundation-blue-dark-hover, #2B5584);
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.menuOption:hover .menuOptionTitle {
  font-weight: 900;
}

.menuOptionDescription {
  color: var(--foundation-blue-dark-hover, #2B5584);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.menuCharaties {
  height: 585px;
  background: var(--foundation-blue-light-active, #C6DCF4);
}

.menuCharatiesTxt {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 86%;
  text-transform: capitalize;
  width: 734px;
  height: 208px;
  text-align: left;
  padding: 50px;
  text-decoration: none;
}

.menuLinkArrow {
  height: 96px;
  width: 96px;
  color: #000;
  margin-bottom: 12px;
  position: relative;
}

.menuImgContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
}

.menuFollowImg {
  width: 260px;
  height: 262px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
}

.menuFollowTxt {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 25px 50px;
  margin: 150px 0 50px 0;
  text-align: left;
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {

  .menuGrid {
    display: flex;
    flex-direction: column;
  }

  .menuSectionTitle {
    transform: rotate(0deg);
    width: initial;
  }

  .mc1,
  .mc2,
  .mc3 {
    display: initial;
  }

  .menuCharatiesTxt {
    font-size: 5rem;
    width: initial;
    padding: 0 0;
  }
}

/* Products styles */

.productTitle {
  margin: 50px 0 25px 110px;
  color: var(--foundation-blue-dark, #356BA5);
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 95px;
  letter-spacing: -8px;
  text-transform: capitalize;
  text-align: left;
}

.productsSection {
  display: inline-flex;
}

.productSortByOptions {
  width: 450px;
}

.productFilterAccordionItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border: none;
  flex-direction: column;
  text-align: left;
}

.accordion-button::after,
.accordion-button::before {
  color: white;
  background-image: url('./Assets/whitePlus.png') !important;
}

#productFilterHeader button {
  color: var(--White, #FFF);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
  border-radius: 12px;
  width: 210px;
  background: var(--foundation-blue-normal-active, #3972B0);
}

.productFilterInput {
  border-radius: none;
  border: 1px solid black;
  margin: 5px;
}

.productFilterLabel {
  color: var(--Black, #0C0C0C);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  text-transform: capitalize;
}

.productDisplayContainer {
  padding: 0 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.productIconContainer {
  text-decoration: none;
  color: black;
}

.productComingSoon {
  background-color: white;
  position: relative;
  top: 10px;
  display: flex;
  width: 117px;
  height: 32px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid black;
  color: var(--Black, #0C0C0C);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  text-transform: capitalize;
}

.productLikedIcon {
  position: relative;
  left: 90%;
  bottom: 30px;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.productImgContainer {
  margin: 5px;
  height: 440px;
  width: 390px;
  background-color: lightgray;
  padding: 15px 35px;
  background-position: center;
  background-size: cover;
}

.productName {
  color: var(--Black, #0C0C0C);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
}

.productClassification {
  color: var(--Black, #0C0C0C);

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  text-transform: capitalize;
}

.productPrice {
  color: var(--Black, #0C0C0C);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
}

.productIconLink {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  position: relative;
  top: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 40px;
  padding: 16px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
  background: var(--foundation-blue-normal-hover, #4080C6);
}

.productIconContainer:hover .productIconLink {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 600px) {

  .productsSection {
    display: initial;
  }
}

/* FAQ styles */

#faqWelcome {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accentLine {
  margin: 15px;
  width: 25%;
  border-bottom: 1px solid black;
}

.questionContainer {
  background-color: var(--color-1);
  padding: 10px;
  margin: 15px 40px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question {
  font-size: 25px;
  font-family: var(--font-1);
}

.answer {
  font-size: 20px;
}

#faqFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

#faqLogo {
  width: 150px;
}

/* Item styles */
.ItemSection {
  margin-bottom: 50px;
  border-bottom: 1px solid black;
}

.ItemPath {
  text-align: left;
  padding-left: 90px;
  color: var(--foundation-blue-normal-hover, #4080C6);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  text-transform: capitalize;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.ItemGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr;
}

.mic1 {}

.ItemImg {
  width: 624px;
  height: 697px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  margin: 20% auto;
}

.mic2 {
  border-left: 1px solid black;
}

.ItemTitle {
  color: var(--foundation-blue-dark, #356BA5);
  font-family: Montserrat, sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  letter-spacing: -8px;
  text-transform: capitalize;
}

.ItemPriceContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

}

.ItemPrice {
  color: var(--foundation-blue-dark, #356BA5);
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  text-transform: capitalize;
}

.ItemAddToCart {
  border: 1px solid var(--foundation-blue-normal, #478EDC);
  color: var(--foundation-blue-normal, #478EDC);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  text-transform: capitalize;
  display: flex;
  width: 202px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.ItemDescription {
  text-align: left;
  margin: 50px;
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  text-transform: capitalize;
}

.mic3 {
  border-top: 1px solid black;
}

.ItemDropdowns {
  margin: 50px;
}

.mic4 {
  border-left: 1px solid black;
  border-top: 1px solid black;

}

.ItemLogo {
  width: 70%;
  flex-shrink: 0;
}

.itemAccordionHeader button {
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {

  .ItemPath {
    padding-left: 0px;
    font-size: 18px;
    padding: 0px 10px;
  }

  .ItemGrid {
    display: flex;
    flex-direction: column;
  }

  .ItemImg {
    width: 100%;
    height: auto;
  }

}

/* Success Page */

.successOrderNum {
  color: var(--foundation-blue-dark, #356BA5);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  text-transform: capitalize;
}

.SuccessMsg {
  color: var(--foundation-blue-dark, #356BA5);
  text-align: center;
  font-family: Montserrat;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  /* 131.944% */
  letter-spacing: -8px;
  text-transform: capitalize;
  width: 30%;
  margin: 0 auto;
}

.successIcon {
  width: 500px;
  height: 500px;
  flex-shrink: 0;
}

.successNewsLetter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid black;
}

.successNewsLetterTitle {
  color: var(--foundation-blue-dark, #356BA5);
  /* H5 */
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 28px */
  text-transform: capitalize;
}

.successNewsLetterInputContainer {
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  width: 496px;
  height: 40px;
  align-items: center;
  flex-shrink: 0;
}

.successNewsLetterInput {
  color: #235892;

  /* bodyXS */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 21.6px */
  text-transform: capitalize;
}

.successInputArrow {
  position: relative;
  right: 35px;
  background-color: white;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.successNewsLetterFooter {
  color: var(--foundation-blue-dark, #356BA5);

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
}

/* ################################## Component styles ################################## */

/* Nav bar styling */
#navbar {
  background-color: var(--color-4);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  padding: 25px 10% 25px 25%;
}

.navLinkContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  min-width: 250px;
}

.navLink {
  color: var(--neutral-gray-404040, #404040);
  text-align: center;
  font-size: 18px;
  color: black;
  text-decoration: none;
}

.navTitleLogo {
  background-image: url('./Assets/stusTitle.png');
  width: 262px;
  height: 105px;
  position: relative;
  object-fit: cover;
}

.navTitle {
  color: var(--pb-d);
  font-size: 65px;
  transform: rotate(-5deg);
  font-weight: 100;
}

.navTitleInfo {
  position: absolute;
  right: -15px;
  bottom: -10px;
  line-height: 5px;
  font-size: 15px;
}

#navDropdown {
  background-color: var(--color-4);
  border: none;
  color: black;
}

.navIconContainer {
  display: flex;
}

.navIcon {
  width: 45px;
  padding: 5px;
}

.navCartIcon {
  cursor: pointer;
}

@media screen and (max-width: 600px) {

  .navTitleSection {
    width: 60%;
    font-size: 15px;
  }

  #navbar {
    padding: 0;
  }

  .navLinkContainer {
    min-width: min-content;
    flex-direction: column;
  }

  .navLink {
    padding: 3px 7px;
  }
}

/* Loading icon styles */
.loadingIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes waveAnimation {
  0% {
    top: 100%;
  }

  100% {
    top: -30px;
  }
}

.waveContainer {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.wave1,
.wave2 {
  animation: waveAnimation 9s linear infinite;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: -30;
  height: 200%;
}

.inverse {
  color: white;
}

.coffeeCup {
  background-attachment: fixed;
  height: 190px;
  z-index: 999;
  filter: invert(100%);
}

.loadingMsgContainer {
  color: grey;
  margin: 20px 0 0 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
}


/* Cart styles */

.cartPopover {
  /* width: 621px;
  height: 523px; */
}

.cartTitle {
  background: var(--foundation-blue-dark, #356BA5);
  color: #FFF;
  font-family: var(--font-1);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  /* 296.875% */
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartHeaderIcon {
  width: 27px;
  height: 27px;
  flex-shrink: 0;
}

.itemsInCart {
  color: var(--text-text-dark, #1A1F36);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: var(--font-1);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.cartItemDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #D9D9D9;
}

.cartItemmetacontainer {
  display: flex;
  gap: 20px;
}

.popover {
  margin: 25px 0;
  min-width: 621px;
  min-height: 59px;
  flex-shrink: 0;
}

.cartItemImg {
  width: 65.506px;
  height: 65.506px;
  flex-shrink: 0;
}

.cartItemMeta {}

.cartItemQty {
  color: var(--text-text-gray, #697386);
  text-align: right;
  font-family: var(--font-1);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.18px;
}

.cartTotal {
  display: flex;
  font-size: 20px;
  margin-left: 40%;
  width: 257.571px;
  padding: 12px 0px;
  justify-content: space-between;
  align-items: flex-end;
}

.cartCheckoutArrow {
  width: 42px;
  height: 42px;
}

.cartFooter {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: var(--foundation-blue-dark, #356BA5);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: var(--font-1);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  /* 142.857% */
}

.cartItemCount {
  display: flex;
}

.cartItemCountAdj {
  padding: 0 5px;
  color: var(--text-text-gray, #697386);
}

@-webkit-keyframes zoomin {
  0% {
    -webkit-transform: scale(1);
  }

  100% {
    -webkit-transform: scale(2);
  }
}

@media screen and (max-width: 600px) {

  .hat-logo {
    display: none;
  }

  #navbar {
    position: sticky;
    top: 0;
  }

  .popover {
    margin-top: 50px;
    min-width: 80%;
    padding-left: 10px;
  }

  .cartTotal {
    width: auto;
  }

}

/* footer styles */
.footer {
  display: flex;
  align-items: center;
  border-top: 1px solid black;
  justify-content: space-around;
  text-align: left;
  padding: 50px 100px;
}

.footerSocialsSection {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footerInfoSection {
  width: 45%;
  display: flex;
  justify-content: space-around;
}

.footerTitle {
  font-size: 20px;
  font-family: var(--font-1);
  font-weight: 600;
}

.footerBody {
  font-size: 12px;
  font-family: var(--font-2);
}

.footerIcon {
  height: 24px;
  width: 24px;
}

#socialsContainer {
  font-size: large;
  height: 10vh;
  align-items: center;
  display: flex;
  gap: 15px;
}

#inputContainer {
  display: flex;
}

#signUp {
  height: 95%;
  cursor: pointer;
  position: relative;
  top: 3px;
  right: 45px;
  background-color: white;
  display: flex;
  align-items: center;
}

#signUpIcon {
  height: 16px;
  width: 16px;
}

.newsLetterMsg {
  padding: 15px 0px;
}

@media screen and (max-width: 600px) {

  /* newsletter mobile styling */
  .newsLetter p {
    font-size: 13px;
  }

  #emailInput {
    position: relative;
    left: 3%;
    width: 90%;
    height: 5rem;
    font-size: 18px;
  }

  #signUp {
    height: 4.8rem;
    cursor: pointer;
    position: relative;
    min-width: 50px;
    right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: none;
    border: none;
  }

  .footerInfoSection {
    display: none;
  }

  .footer {
    padding: 20px;
  }

  .footerSocialsSection {
    width: fit-content;
  }
}

/*  Icon styling  */
.iconContainer {
  border-radius: 35px;
  min-height: 350px;
  cursor: pointer;
  background-color: var(--color-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  position: relative;
  color: #111;
  text-decoration: none;
  width: 20vw;
  min-width: 230px;
  padding: 0 10px;
}

.itemMeta {
  text-decoration: none;
  color: black;
}

.iconContainer img:hover {
  transform: scale(1.1);
}

.iconProductImage {
  transition: transform 0.3s ease-out;
  max-width: 25rem;
  width: 70%;
  padding: 20px;
  height: 70%;
  border-radius: 25px;
  min-width: 211px;
  min-height: 211px;
  position: relative;
}

.outOfStockOverlay {
  background-color: rgba(128, 128, 128, 0.367);
  border-radius: 35px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 997;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outOfStockBanner {
  background-color: red;
  color: white;
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 10px;
  width: fit-content;
}

.outOfStockImg {
  border-radius: 25px;
  max-width: 90%;
}

.disabled-link {
  pointer-events: none;
}

@media screen and (max-width: 600px) {}

/* charaties styles */
.charatiesTitle {
  color: var(--foundation-blue-dark);
}

.charatiesDescption {
  color: var(--foundation-blue-dark);
}

.charatiesBoldDesc{
  font-weight: 600;
}

.charContentSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 25px;
  margin: 25px 0;
}

.charitiesContainer {
  /* background-color: grey; */
  width: 40vw;
  height: 40vw;
}

@media screen and (max-width: 600px) {

  .charContentSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .charitiesContainer {
    height: inherit;
    width: 60vh;
    padding: 10px 10px;
  }

  .charitiesTitleImg {
    /* height: 50vh; */
    width: 50vh;
  }
}

/* scroller styles */

.titleLink {
  background-color: #fad018;
  position: absolute;
  right: -6px;
  bottom: 12px;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  color: #111;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  height: auto;
  line-height: 30px;
  padding: 9px 30px;
  border-radius: 0;
}

.announcment {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: opacity 0.3s ease-out, transform 0.7s ease-out;
  animation: easeInFromLeftAnimation 1s ease-in-out;
  width: 40%;
  position: absolute;
  top: 40%;
  background-color: rgba(255, 255, 255, 0.855);
  max-width: fit-content;
}

.announcment h2 {
  margin-bottom: 20px;
  padding-top: 20px;
}

.announcment p {
  padding: 0 20px 72px 20px;
}

.carousel-item {
  width: 100%;
}

.titleImgs {
  margin: auto;
  height: 80vh;
  width: 90%;
  background-size: cover;
  background-position: center;
  animation: zoomin 5s 1;
  animation-fill-mode: forwards;
}

.titleImgs.active {
  animation: zoomin 7s 1;
  animation-fill-mode: forwards;
}

@keyframes zoomin {
  from {
    transform: scale(.97);
  }

  to {
    transform: scale(1);
  }
}

@keyframes easeInFromLeftAnimation {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}